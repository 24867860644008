import React from "react";

import Layout from "../components/layout";

import "../components/image_override.css";

import styles from "./template.module.css";
console.log(styles);

export default ({ pageContext: { post } }) => (
  <Layout navTitle={post.frontmatter.navTitle}>
    <div id={styles.content} dangerouslySetInnerHTML={{__html: post.html}} />
  </Layout>
);
